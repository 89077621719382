import { render, staticRenderFns } from "./DownloadEndDraftDrawer.vue?vue&type=template&id=0afffc61&scoped=true&"
import script from "./DownloadEndDraftDrawer.vue?vue&type=script&lang=js&"
export * from "./DownloadEndDraftDrawer.vue?vue&type=script&lang=js&"
import style0 from "./DownloadEndDraftDrawer.vue?vue&type=style&index=0&id=0afffc61&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0afffc61",
  null
  
)

export default component.exports